<template>
  <div>
    <a-row type="flex" justify="end" id="buttons">
      <a-col :span="2">
        <b-button pill variant="success" :to="{name: 'sampleReceiptMain'}">
          <div class="d-none d-md-block" v-b-tooltip.hover title="Regresar">
            <b-icon icon="arrow-left-circle"/> Regresar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Regresar">
            <b-icon icon="arrow-left-circle"/>
          </div>
        </b-button>
      </a-col>
      <a-col :span="3">
        <b-button variant="warning" pill class="btn-block" @click="Print">
          <div class="d-none d-md-block">
            <b-icon icon="printer"/> Imprimir
          </div>
          <div class="d-md-none">
            <b-icon icon="printer"/>
          </div>
        </b-button>
      </a-col>
    </a-row>
    <h3>INFORMACIÓN DE LA MUESTRA</h3>
    <hr>
    <!-- <pre>{{this.sample}}</pre> -->
    <a-row type="flex" justify="center" class="text-secondary">
      <a-col :span="8" class="border-right border-primary">
        <strong>Material:</strong> &nbsp;
        <span>{{this.sample.sample_material}}</span>
        <br>
        <strong>Corriente:</strong> &nbsp;
        <span>{{this.sample.currents.name}}</span>
        <br>
        <strong>Código de Muestra:</strong> &nbsp;
        <span>{{this.sample.code}}</span>
      </a-col>
      <a-col :span="7" :offset="1">
        <strong>Centro de Trabajo:</strong> &nbsp;
        <span>{{this.sample.work_centers.name}}</span>
        <br>
        <strong>No.Servicio / O.Producción:</strong> &nbsp;
        <span>{{this.sample.invoice_code}}</span>
        <br>
        <strong>Tipo de Ingreso:</strong> &nbsp;
        <span>{{this.sample.sample_type}}</span>
        <br>
        <strong>Cliente Solicitante:</strong> &nbsp;
        <span>{{this.sample.lab_customer}}</span>
      </a-col>
      <a-col :span="8">
        <strong>Cantidad de Muestra Tomada:</strong> &nbsp;
        <span>{{this.sample.quantity}} - {{this.sample.ums.name}}</span>
        <br>
        <strong>Tipo de Contenedor:</strong> &nbsp;
        <span>{{this.sample.container_types.name}}</span>
        <br>
        <strong>Fecha de Recepción:</strong> &nbsp;
        <span>{{moment(this.sample.receipt_sample_date.delivery_dated_at).format('DD/MM/YY')}}</span>
      </a-col>
    </a-row>
    <hr class="bg-secondary">
    <a-row type="flex" justify="center" class="text-secondary mt-4">
      <a-col :sm="{ span: 12 }" :md="{ span:8}" :lg="{ span:6}">
        <h4 class="text-warning">Creación del Perfil</h4>
        <hr class="bg-secondary">
        <strong>Estatus:</strong> &nbsp;
        <span class="text-success">{{this.sample.receipt_sample_date.profile_status}}</span>
        <br>
        <strong>Fecha:</strong> &nbsp;
        <span class="text-success">{{moment(this.sample.receipt_sample_date.profile_dated_at).format('DD/MM/YYYY')}}</span>
      </a-col>
      <a-col :sm="{ span: 11, offset:1 }" :md="{ span:7, offset:1}" :lg="{ span:5, offset:1}">
        <h4 class="text-warning">Inspección Visual</h4>
        <hr class="bg-secondary">
        <strong>Estatus asignación:</strong> &nbsp;
        <span class="text-success">{{this.sample.receipt_sample_date.visual_inspections_status}}</span>
        <br>
        <strong>Fecha asignación:</strong> &nbsp;
        <span class="text-success">
          {{moment(this.sample.receipt_sample_date.visual_inspections_dated_at).format('DD/MM/YYYY')}}
        </span>
        <br>
        <strong>Folio de inspección:</strong> &nbsp;
        <span class="text-success" v-if="sample.visual_inspections">
          {{this.sample.visual_inspections.code}}
        </span>
      </a-col>
      <a-col :sm="{ span: 11, offset:1 }" :md="{ span:7, offset:1}" :lg="{ span:5, offset:1}">
        <h4 class="text-warning">Asignación de Almacén</h4>
        <hr class="bg-secondary">
        <strong>Estatus asignación:</strong> &nbsp;
        <span class="text-success">{{this.sample.receipt_sample_date.set_warehouse_status}}</span>
        <br>
        <strong>Fecha de Ingreso a almacén :</strong> &nbsp;
        <span class="text-success">
          {{moment(this.sample.receipt_sample_date.set_warehouse_dated_at).format('DD/MM/YYYY')}}
        </span>
        <br>
        <strong>Área de Almacenamiento:</strong> &nbsp;
        <span class="text-success" v-if="sample.sample_storage_allocation">
          {{this.sample.sample_storage_allocation.lab_allocations.name}}
          </span>
        <br>
        <strong>Código de Área de Almacenamiento:</strong> &nbsp;
        <span class="text-success" v-if="sample.sample_storage_allocation">
          {{this.sample.sample_storage_allocation.lab_allocations.code}}
        </span>
      </a-col>
      <a-col :sm="{ span: 11, offset:1 }" :md="{ span:7, offset:1}" :lg="{ span:5, offset:1}">
        <h4 class="text-warning">Asignación de Análisis</h4>
        <hr class="bg-secondary">
        <strong>Estatus asignación:</strong> &nbsp;
        <span class="text-success">{{this.sample.receipt_sample_date.lab_analysis_status}}</span>
        <br>
        <strong>Fecha asignación:</strong> &nbsp;
        <span class="text-success">
          {{moment(this.sample.receipt_sample_date.lab_analysis_dated_at).format('DD/MM/YYYY')}}
        </span>
        <br>
        <strong>Folio de análisis:</strong> &nbsp;
        <span class="text-success"> - </span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center" class="text-secondary mt-4">
      <a-col :sm="{ span: 24 }" :md="{ span:8}">
        <h4 class="text-warning">Plan de Muestreo</h4>
        <hr class="bg-secondary">
        <h6 class="text-real-blue">Información General</h6>
        <strong>Muestreo:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans">
          {{this.sample.receipt_sample_plan.sample_plans.code}}
        </span>
        <br>
        <strong>Preparación de muestra:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans">
          {{this.sample.receipt_sample_plan.sample_plans.sample_preparation_type}}
        </span>
      </a-col>
      <a-col :sm="{ span: 12 }" :md="{ span:7, offset:1}" class="border-right border-primary">
        <h6 class="text-real-blue">Atmósfera explosiva (LEL)</h6>
        <strong>CO:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans">
          {{this.sample.receipt_sample_plan.sample_plans.lab_security_measures.co}}
        </span>
        <br>
        <strong>H2S:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans">
          {{this.sample.receipt_sample_plan.sample_plans.lab_security_measures.h2s}}
        </span>
        <br>
        <strong>CO2:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans">
          {{this.sample.receipt_sample_plan.sample_plans.lab_security_measures.co2}}
        </span>
        <br>
        <strong>Temperatura de los recipientes:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans">
          {{this.sample.receipt_sample_plan.sample_plans.lab_security_measures.temperature}}
        </span>
        <h6 class="text-real-blue">Temperatura y PH</h6>
        <strong>Lectura Ph:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans">
          {{this.sample.receipt_sample_plan.sample_plans.lab_security_measures.ph_reading}}
        </span>
      </a-col>
      <a-col :sm="{ span: 12 }" :md="{ span:7, offset:1}">
        <h6 class="text-real-blue">EPP que debe portar al realizar el análisis</h6>
        <div v-show="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans && sample.receipt_sample_plan.sample_plans.ppe_samples.is_security_shoes">
            <strong>
            Zapatos de Seguridad
          </strong>
          <br>
        </div>
        <div v-show="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans && sample.receipt_sample_plan.sample_plans.ppe_samples.is_tyek">
          <strong>
            TYEK
          </strong>
          <br>
        </div>
        <div v-show="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans && sample.receipt_sample_plan.sample_plans.ppe_samples.is_security_helmet">
          <strong>
            Casco de Seguridad
          </strong>
          <br>
        </div>
        <div v-show="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans && sample.receipt_sample_plan.sample_plans.ppe_samples.is_gloves">
          <strong>
            Guantes
          </strong>
          <br>
        </div>
        <div v-show="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans && sample.receipt_sample_plan.sample_plans.ppe_samples.is_full_face">
          <strong>
            Full Face
          </strong>
          <br>
        </div>
        <div v-show="sample.receipt_sample_plan && sample.receipt_sample_plan.sample_plans && sample.receipt_sample_plan.sample_plans.ppe_samples.is_other">
          <strong>
            {{this.sample.receipt_sample_plan.sample_plans.ppe_samples.other_description}}
          </strong>
        </div>
      </a-col>
    </a-row>
    <hr class="bg-primary">
    <a-row type="flex" justify="center" class="text-secondary">
      <a-col :span="11">
        <strong>El muestreo realizado es representativo:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan.sample_plans.representative_sample_check">SI</span>
        <span v-else class="text-danger">NO</span>
        <br>
        <div class="bg-white py-1 px-1">
          <p>{{this.sample.receipt_sample_plan.sample_plans.representative_sample_notes}}</p>
        </div>
      </a-col>
      <a-col :span="11" :offset="1">
        <strong>Se presenta dificultad en tomas de las muestras:</strong> &nbsp;
        <span class="text-success" v-if="sample.receipt_sample_plan.sample_plans.difficult_sample_check">SI</span>
        <span v-else class="text-danger">NO</span>
        <div class="bg-white py-1 px-1">
          <p>{{this.sample.receipt_sample_plan.sample_plans.difficult_sample_notes}}</p>
        </div>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" class="text-secondary mt-4">
      <a-col :span="8">
        <strong>Nombre Entrego la Muestra:</strong> &nbsp;
        <span class="text-success">{{this.sample.receipt_sample_date.delivery_responsible}}</span>
      </a-col>
      <a-col :span="8">
        <strong>Nombre Recibe la Muestra:</strong> &nbsp;
        <span class="text-success">{{this.sample.receipt_sample_date.receipt_responsible}}</span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
const defaultSample = {
  id: 1,
  work_center_id: null,
  code: '',
  warehouse_manual_code: '',
  invoice_code: 'Servicios',
  sample_type: 'Manual Interna',
  lab_customer: '',
  sample_material: '',
  service: {
    type: 'manual',
    id: 0,
  },
  quantity: '',
  u_m_s_id: '',
  current_id: '',
  container_type_id: '',
  notes: null,
  deleted_at: null,
  created_at: '',
  updated_at: '',
  quantity_in_warehouse: null,
  work_centers: {
    id: null,
    sap_code: '',
    code: '',
    name: '',
  },
  ums: {
    id: null,
    sap_code: '',
    code: '',
    name: '',
  },
  currents: {
    id: null,
    code: '',
    name: '',
  },
  container_types: {
    id: null,
    code: '',
    name: '',
  },
  receipt_sample_date: {
    id: null,
    receipt_sample_id: null,
    request_dated_at: '',
    request_responsible: '',
    request_status: 'Pendiente',
    delivery_dated_at: '',
    delivery_responsible: null,
    delivery_status: 'Pendiente',
    receipt_dated_at: '',
    receipt_responsible: null,
    receipt_status: 'Pendiente',
    profile_dated_at: '',
    profile_responsible: null,
    profile_status: 'Pendiente',
    visual_inspections_dated_at: '',
    visual_inspections_responsible: null,
    visual_inspections_status: 'Pendiente',
    set_warehouse_dated_at: '',
    set_warehouse_responsible: null,
    set_warehouse_status: 'Pendiente',
    lab_analysis_dated_at: '',
    lab_analysis_responsible: null,
    lab_analysis_status: 'Pendiente',
    deleted_at: null,
    created_at: '2022-01-07T01:27:47.983000Z',
    updated_at: '2022-01-11T01:20:24.867000Z',
  },
  receipt_sample_plan: {
    sample_plan_id: '',
    receipt_sample_id: '',
    sample_plans: {
      id: null,
      work_center_id: '',
      code: '',
      sample_plan_type: 'Material Compósito',
      sample_preparation_type: 'Mezclado',
      security_flag: true,
      ppe_flag: true,
      difficult_sample_check: true,
      difficult_sample_notes: '',
      representative_sample_check: true,
      representative_sample_notes: '',
      created_at: '',
      updated_at: '',
      close_plan_check: false,
      work_centers: {
        id: null,
        sap_code: '',
        code: '',
        name: '',
      },
      lab_security_measures: {
        id: null,
        sample_plan_id: '',
        co: '',
        co2: '',
        h2s: '',
        ph_reading: '',
        temperature: '',
        created_at: '',
        updated_at: '',
      },
      ppe_samples: {
        id: null,
        sample_plan_id: '4',
        is_security_shoes: false,
        is_gloves: false,
        is_tyek: false,
        is_full_face: false,
        is_security_helmet: false,
        is_other: false,
        other_description: '',
        created_at: '',
        updated_at: '',
      },
    },
  },
  sample_storage_allocation: {
    id: null,
    receipt_sample_id: '',
    potential_dangerous: '',
    sample_container_type_id: '',
    expiration_dated_at: '',
    lab_allocation_id: '',
    lab_storage_time_id: '',
    created_at: '',
    updated_at: '',
    sample_container_types: {
      id: null,
      work_center_id: '',
      name: '',
      description: '',
      deleted_at: null,
      created_at: '',
      updated_at: '',
    },
    lab_allocations: {
      id: 2,
      work_center_id: '',
      code: '',
      name: '',
      description: '',
      deleted_at: null,
      created_at: '',
      updated_at: '',
    },
    lab_storage_times: {
      id: 1,
      work_center_id: '',
      code: '',
      name: '',
      description: '',
      deleted_at: null,
      created_at: '',
      updated_at: '',
    },
  },
  visual_inspections: {
    id: null,
    receipt_sample_id: null,
    code: 'V_0000000001',
    user_id: 1,
    status: false,
    received_at: '',
    inspected_at: '',
    text: '',
    shift_id: null,
    photos: null,
    created_at: '',
    updated_at: '',
    deleted_at: null,
  },
}
export default {
  name: 'sampleDetail',
  mixins: [apiV2Mixin],
  data() {
    return {
      id: this.$route.params.id,
      default: JSON.stringify(defaultSample),
      sample: defaultSample,
    }
  },
  methods: {
    async FetchSample() {
      const sample = await this.AxiosGetv2(`receiptSamples/${this.id}`, {})
      if (sample) {
        this.sample = sample.data
      } else {
        this.sample.form = JSON.parse(this.default)
      }
    },
    Print() {
      window.print()
    },
  },
  async mounted() {
    await this.FetchSample()
  },

}
</script>

<style>
@media print {
    #buttons, header, footer, aside, b-button {
        display: none;
    }
}
</style>
